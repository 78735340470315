<template>
    <div>
        <!-- <LevelComponent v-if="currentDevice.type == 'level'" /> -->
        <LaporanLevel v-if="currentDevice.type == 'level'" />
        <WqComponent v-if="currentDevice.type == 'WQ'" />
        <LaporanRain v-if="currentDevice.type == 'rain'" />
        <!-- <RainComponent v-if="currentDevice.type == 'rain'" /> -->
        <!-- <WeatherComponent  v-if="currentDevice.type == 'weather'" /> -->
        <LaporanWeather  v-if="currentDevice.type == 'weather'" />
        <TimelapseComponent  v-if="currentDevice.type == 'camera'" />
        <Wavegauge  v-if="currentDevice.type == 'wavegauge'" />
        <Wavebouy  v-if="currentDevice.type == 'wavebuoy'" />
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
// import LevelComponent from './level.vue'
import LaporanLevel from './laporan_level.vue'
import LaporanRain from './laporan_rain.vue'
import WqComponent from './waterquality.vue'
// import RainComponent from './raingouge.vue'
// import WeatherComponent from './weatherstation.vue'
import LaporanWeather from './laporan_cuaca.vue'
import TimelapseComponent from './camera.vue'
import Wavegauge from './wavegauge.vue'
import Wavebouy from './wavebouy.vue'
export default {
    computed:{
          ...mapGetters({
            currentDevice:'device/currentDevice',
        })
    },
    
    created() {
        console.log("CURRENT DEVICE :", this.currentDevice)        
    },
    watch: {
        currentDevice: function (newVal,oldVal) {
            if (!newVal) {
                this.$router.push('/report'); // Redirect if no device is set
            }
        },
    },
    components:{WqComponent,TimelapseComponent, Wavegauge, Wavebouy, LaporanLevel, LaporanRain, LaporanWeather}
}
</script>