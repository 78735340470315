<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8 mt-4">
            <div class="flex flex-wrap items-center content-between justify-between">
                <div>
                    <span class="font-semibold text-base">Data Rekap Laporan Perangkat Ketinggian</span>
                    <div class="relative mt-2 mr-4">
                        <span>Terakhir Diperbarui: <span>{{ lastUpdated }}</span></span>
                    </div>
                    <div class="relative mt-2 mr-4">
                        <span>Nama Device: <span>{{ deviceName }}</span></span>
                    </div>
                </div>
                <div class="flex">
                    <div class="relative">
                        <button @click="toggleYearDropdown"
                            class="bg-blue-500 text-white px-4 py-2 mr-2 rounded-lg text-xs flex items-center gap-2 shadow-lg hover:scale-105">
                            <i class="fas fa-calendar"></i> {{ selectedYear }}
                        </button>
                        <div v-if="showYearDropdown"
                            class="absolute right-0 mt-2 w-40 bg-white border rounded-lg shadow-lg">
                            <button v-for="option in yearOptions" :key="option" @click="selectYear(option)"
                                class="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100">
                                <i class="fas fa-calendar-alt mr-2 text-blue-500"></i> {{ option }}
                            </button>
                        </div>
                    </div>
                    <div class="text-xs relative">
                        <input v-model="keyword" type="text"
                            class="block border border-gray-200 w-72 bg-white pr-2 pl-8 px-4 rounded-lg text-xs"
                            placeholder="Pencarian" @keyup.enter="searchData()" />
                    </div>
                </div>
            </div>
        </div>

        <div class="rounded-2xl bg-white p-8 mt-4">
            <div v-if="isLoading" class="flex flex-col justify-center items-center h-32">
                <i class="fas fa-spinner fa-spin text-4xl text-blue-500"></i>
                <span class="mt-2 text-gray-500 text-sm">Data sedang memuat...</span>
            </div>

            <div v-else class="overflow-x-scroll max-w-[80vw] max-h-[50vh] rounded-2xl">
                <table class="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr>
                            <th rowspan="2" class="bg-blue-900 text-white border">Bulan</th>
                            <th rowspan="2" class="bg-blue-900 text-white border">Aksi</th>
                            <th colspan="2" class="bg-blue-900 text-white px-10 py-2 border">Ketinggian Air (cm)</th>
                        </tr>
                        <tr>
                            <td v-for="year in selectedYear.split(' - ')" :key="year"
                                class="bg-blue-900 text-white px-4 py-2 border">
                                {{ year }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filteredData" :key="item.tgl" class="border-b hover:bg-gray-300">
                            <td class="px-4 py-2 border">{{ item.month }}</td>
                            <td class="px-8 py-2 border">
                                <button
                                    class="bg-blue-500 text-white px-6 py-2 rounded-lg text-xs flex items-center gap-2 shadow-lg hover:scale-105"
                                    @click="goToReport(item)">
                                    <i class="fas fa-chart-bar"></i> Lihat Chart
                                </button>
                            </td>
                            <td class="px-4 py-2 border">
                                {{ getKetinggian(item, selectedYear.split(' - ')[0]) }}
                            </td>
                            <td class="px-4 py-2 border">
                                {{ getKetinggian(item, selectedYear.split(' - ')[1]) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import globalMixin from '../../mixin/global'

export default {
    data() {
        return {
            data: [],
            isLoading: false,
            deviceName: '-', // Default jika tidak ada data
            selectedYear: '2024 - 2025',
            showYearDropdown: false,
            keyword: '',
            yearOptions: ['2024 - 2025', '2022 - 2023'],
        };
    },
    mixins: [globalMixin],
    computed: {
        ...mapGetters({
            reportDevice: 'device/reportDevice',
            token: 'auth/token',
        }),
        filteredData() {
            const years = this.selectedYear.split(' - ').map(y => parseInt(y));
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            return this.data.filter(item => {
                if (item.year === years[0]) {
                    return true; // Tampilkan semua data untuk tahun pertama
                } else if (item.year === years[1]) {
                    if (years[1] === currentYear) {
                        return item.month_number <= currentMonth; // Batasi hingga bulan sekarang jika tahun kedua adalah tahun ini
                    }
                    return true; // Tampilkan semua data untuk tahun kedua jika bukan tahun berjalan
                }
                return false;
            });
        },
        lastUpdated() {
            if (this.data.length > 0) {
                const latestData = this.data[this.data.length - 1]; // Ambil data terbaru
                return moment(latestData.tgl, "YYYY-MM").format("DD MMM YYYY");
            }
            return "-";
        }

    },
    methods: {
        async getData() {
            this.isLoading = true;
            const [startYear, endYear] = this.selectedYear.split(' - ');
            try {
                const response = await this.axios.get(`v1/device_data/allreport/${this.$route.params.id}`, {
                    params: { filter: 'year', start_year: startYear, end_year: endYear },
                    ...this.config
                });

                if (response.data && Array.isArray(response.data.data)) {
                    this.data = response.data.data.map(item => ({
                        ...item,
                        month_number: this.getMonthNumber(item.month)
                    }));

                    console.log("Data berhasil diambil:", this.data); // Debugging

                    if (this.data.length > 0 && this.data[0].Device) {
                        this.deviceName = this.data[0].Device.name;
                    }
                } else {
                    console.error("Expected an array in 'data', but received:", response.data);
                    this.data = [];
                }
            } catch (err) {
                console.error("Error fetching data:", err.response?.data || err.message);
            } finally {
                this.isLoading = false;
            }
        },
        getMonthNumber(monthName) {
            const months = {
                "Jan": 1, "Feb": 2, "Mar": 3, "Apr": 4, "May": 5, "Jun": 6,
                "Jul": 7, "Aug": 8, "Sep": 9, "Oct": 10, "Nov": 11, "Dec": 12
            };
            return months[monthName] || 0;
        },
        toggleYearDropdown() {
            this.showYearDropdown = !this.showYearDropdown;
        },
        selectYear(year) {
            this.selectedYear = year;
            this.showYearDropdown = false;
            this.getData();
        },
        getKetinggian(item, year) {
            return item.year === parseInt(year) ? `${parseFloat(item.real_ketinggian || 0).toFixed(2)} cm` : 'Data tidak tersedia';
        },
        searchData() {
            console.log("Searching for:", this.keyword);
        },
        goToReport(item) {
            this.$router.push('/report');
        }
    },
    created() {
        this.getData();
    }
};
</script>
